import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/pro-regular-svg-icons/faPlayCircle"
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay"
import Button from "@bit/azheng.joshua-tree.button"
import ImageMeta from "./ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import TheaterVideo from "@components/Theater/TheaterVideo"
import DropdownButton from "../components/DropdownButton"
import { wrapSup } from "../utils/utils"

import "./SingleProcedureHero.scss"

const SingleProcedureHero = ({
  settings,
  iconPosition,
  heading,
  image,
  imageMobile,
  language,
  youtube,
  dropdownButton,
  animationStyle = "slide-left",
  goBackUrl
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const copyright = `Copyright ©Nuvolum ${new Date().getFullYear()}. All Rights Reserved.`

  return (
    <div className={`sph ${iconPosition ? iconPosition : ""}`}>
      {goBackUrl && (
        <Button
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={language === "es" ? "/es/procedimientos/" : goBackUrl}
        />
      )}
      <div className="sph__heading">
        <h1 dangerouslySetInnerHTML={{ __html: wrapSup(heading) }}></h1>
        {/* <MarkdownViewer priority={1} markdown={} /> */}
        <div className="sph__btn-stack">
          <div>
            <TheaterVideo
              language={language}
              videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
              controls
              playing
              //   overVideo
              hasWatchVideo
              buttonClass="contained is-hidden-touch"
            />
          </div>
          {dropdownButton.hasThisSection && (
            <div className="is-hidden-touch">
              <DropdownButton
                isCenteredMobile
                heading="Other Locations"
                links={dropdownButton.links}
              />
            </div>
          )}
        </div>
        <div className=" is-hidden-desktop">
          <TheaterVideo
            language={language}
            videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
            controls
            playing
            asWrapper
            wrapperClassName="sph__mobile-theater"
            hasWatchVideo
            buttonClass="contained"
          >
            <FontAwesomeIcon icon={faPlay} />
          </TheaterVideo>
        </div>
        <span className="sph__copyright">{copyright}</span>
      </div>

      <div className="sph__theater">
        <div
          className={`sph__icon ${settings?.iconContainerClass || ""} ${
            animationStyle ? animationStyle : "slide-left"
          } ${iconPosition ? iconPosition : ""} ${
            isImageLoaded ? "animate" : ""
          }`}
        >
          <ImageMeta
            className={`${settings?.imageProportion} ${
              imageMobile ? "is-hidden-touch" : ""
            }`}
            hasLoaded={() => setIsImageLoaded(true)}
            publicId={image}
          />

          {imageMobile && (
            <ImageMeta
              className={`is-hidden-desktop ${settings?.imageProportion} `}
              hasLoaded={() => setIsImageLoaded(true)}
              publicId={imageMobile}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SingleProcedureHero
